:root {
  --base-gap: 15px;
  --inter-element-gap: 10px;

  --header-height: 40px;
  --navbar-height: 50px;
  --max-content-width: 600px;

  --background-color-primary: #f0f2f5;
  --border-color-primary: #d9d9d9;
  --border-color-secondary: rgba(0, 0, 0, 0.1);
  --border-color-tetriary: rgba(0, 0, 0, 0.05);

  --text-color-primary: rgba(0, 0, 0, 0.85);
  --text-color-secondary: rgba(0, 0, 0, 0.45);

  --form-control-height: 30px;
  --form-control-border-color: var(--border-color-primary);
  --form-control-icon-color: rgba(0, 0, 0, 0.25);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color-primary);
}

select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: var(--form-control-height);
  line-height: var(--form-control-height);

  border: 1px solid var(--form-control-border-color);
  color: #333;
  background: #fff;
  border-radius: 3px;
}

input[type='url'] {
  appearance: none;
}

abbr,
.smallcaps {
  font-size: 0.8em;
  letter-spacing: 0.15em;
  margin-right: -0.15em;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
