.app {
  min-height: 100vh;
  padding-top: var(--header-height);

  padding-top: calc(constant(safe-area-inset-top) + var(--header-height));
  padding-top: calc(env(safe-area-inset-top) + var(--header-height));
}

.app-main {
  padding: var(--base-gap);
  padding-top: 10px;

  width: 100%;
  max-width: var(--max-content-width);
  margin: auto;
}
